"use strict";

/** Let's create a namespace for all the JS we have, that way we can modularise it a bit when we get to refactoring of that */
var vio = vio || {};

// these should be separate modules, but we're not there yet


// create bootstrap function @see https://github.com/jfriend00/docReady
(function(funcName, baseObj) {
    funcName = funcName || "docReady";
    baseObj = baseObj || window;
    var readyList = [];
    var readyFired = false;
    var readyEventHandlersInstalled = false;
    function ready() {
        if (!readyFired) {
            readyFired = true;
            for (var i = 0; i < readyList.length; i++) {
                readyList[i].fn.call(window, readyList[i].ctx);
            }
            readyList = [];
        }
    }
    function readyStateChange() {
        if ( document.readyState === "complete" ) {
            ready();
        }
    }
    baseObj[funcName] = function(callback, context) {
        if (readyFired) {
            setTimeout(function() {callback(context);}, 1);
            return;
        } else {
            readyList.push({fn: callback, ctx: context});
        }
        if (document.readyState === "complete" || (!document.attachEvent && document.readyState === "interactive")) {
            setTimeout(ready, 1);
        } else if (!readyEventHandlersInstalled) {
            if (document.addEventListener) {
                document.addEventListener("DOMContentLoaded", ready, false);
                window.addEventListener("load", ready, false);
            } else {
                document.attachEvent("onreadystatechange", readyStateChange);
                window.attachEvent("onload", ready);
            }
            readyEventHandlersInstalled = true;
        }
    }
}('bootstrap', vio));

/**
 * @dependency vio.config
 */
vio.debug = function(vio) {
    var messages = [], log = false;
    if (typeof front_end_config !== 'undefined' && front_end_config.build.env === 'dev') {
        log  = true;
    }
    return {
        log: function(message, params) {
            var date = new Date();
            var msg = date.toISOString() + ": " + message;
            if (typeof params !== 'undefined') {
                msg += ", params: " + JSON.stringify(params);
            }
            messages.push(msg);
            if (typeof console.log !== 'undefined' && log) {
                console.log(msg);
            }
        },
        get: function() {
            return messages;
        },
        dump: function() {
            if (typeof console.log !== 'undefined') {
                console.log(messages);
            } else {
				throw new Error('No console object to dump to');
            }
        }
    };
}(vio);

vio.util = function(vio) {
    return {
        /**
         * Returns a random number between min and max
         *
         * @param min
         * @param max
         * @returns number
         */
        random: function (min, max) {
            return Math.round(Math.random() * (max - min) + min);
        },

        /**
         * Used mostly in event handlers - only execute given function once every wait milliseconds
         * @param func
         * @param wait
         * @param immediate
         * @returns {Function}
         */
        debounce: function(func, wait, immediate) {
            var timeout;
            return function() {
                var context = this, args = arguments;
                var later = function() {
                    timeout = null;
                    if (!immediate) {
                        func.apply(context, args);
                    }
                };
                var call_now = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (call_now) {
                    func.apply(context, args);
                }
            };
        },

        merge_objects: function() {
            var target = {};
            var objects = arguments;
            for (var i=0; i<objects.length; i++) {
                for (var key in objects[i]) {
                  if (Object.prototype.hasOwnProperty.call(objects[i], key)) {
                    target[key] = objects[i][key];
                  }
                }
            }
            return target;
        },

        text: {
            camelise: function(str) {
                return str.replace(/(?:^\w|[A-Z]|\b\w|\b\-|\b\.|\s+|\-+|\.+)/g, function(match, index) {
                    if (match == "-" || match == "." || match == " ") {
                        return "";
                    } // or if (/\s+/.test(match)) for white spaces
                    return index == 0 ? match.toLowerCase() : match.toUpperCase();
                });
            },
            hash: function(str){
                var hash = 0, char;
                str = String(str); // cast to string
                if (str.length == 0) {
                    throw 'Trying to get a hash out of empty string';
                }
                for (var i = 0; i < str.length; i++) {
                    char = str.charCodeAt(i);
                    hash = ((hash<<5) - hash) + char;
                    hash = hash & hash; // Convert to 32bit integer
                }
                return Math.abs(hash);
            }
        },

        dom: function() {
            // returns element by param or element if it's already an element
            var get_element = function(param) {
                if (typeof param.nodeName !== 'undefined') {
                    return param;
                } else {
                    return document.querySelector(param);
                }
            };

            // applies a function on every element returned by css_selector
            var process_elements = function(css_selector, func) {
                var elements = document.querySelectorAll(css_selector);
                var el;
                for (var i=0; i<elements.length; i++) {
                    el = elements[i];
                    func(el);
                }
            };

            var event_handlers = {};

            return {
                /**
                 * Extracts data from an element and returns an object with key-value pairs
                 * it assumes "data-config" prefix, if prefix is false it returns everything
                 */
                data: function(param, prefix) {
                    if (typeof prefix == 'undefined') {
                        prefix = 'data-config-';
                    }

                    // is it HTML document?
                    var obj = {}, name, val;
                    if (typeof param.nodeName !== "undefined") {
                        var attributes = param.attributes;

                        for (var i=0; i<attributes.length; i++) {
                            if (attributes[i].nodeName.substring(0, prefix.length) != prefix && prefix != false) {
                                continue;
                            }

                            name = (prefix != false) ? attributes[i].nodeName.replace(prefix, '') : attributes[i].nodeName;
                            val  = attributes[i].value;
                            if (val == "true" || val == "yes" || val == "enabled" || val == "on") {
                                val = true;
                            } else if (val == "false" || val == "no" || val == "disabled" || val == "off") {
                                val = false;
                            }
                            obj[name] = val;
                        }
                    }
                    return obj;
                },

                bem: function() {
                    var get_components = function(str) {
                        var module, element, modifier;

                        // refuse to work off anything but string
                        if (typeof str !== 'string') {
                            vio.debug.log('attempting to get BEM components off a non-string');
                            throw 'attempting to get BEM components off a non-string';
                        }

                        // BEM structure is "module__element--modifier"

                        // do we have both __ and -- ?
                        if ((str.indexOf('__') > 0) && (str.indexOf('--') > 0)) {
                            module = str.substring(0, str.indexOf('__'));
                            element = str.substring(str.indexOf('__')+2, str.indexOf('--'));
                            modifier = str.substring(str.indexOf('--')+2);

                        // only __?
                        } else if (str.indexOf('__') > 0) {
                            module = str.substring(0, str.indexOf('__'));
                            element = str.substring(str.indexOf('__') + 2);
                            modifier = false;

                        // only --?
                        } else if (str.indexOf('--') > 0) {
                            module = str.substring(0, str.indexOf('--'));
                            element = false;
                            modifier = str.substring(str.indexOf('--') + 2);

                        // neither?
                        } else {
                            module = str;
                            element = false;
                            modifier = false;
                        }

                        // structure is always the same
                        return {
                            module: module,
                            element: element,
                            modifier: modifier
                        };
                    };

                    var extract_bem_classes_from_element = function(elem) {
                        if (typeof elem.nodeName === 'undefined') {
                            vio.debug.log('Trying to get a BEM class from not-dom-element');
                            throw 'Trying to get a BEM class from not-dom-element';
                        }

                        // search for anything with __ in it
                        var str;
                        for (var i=0; i<elem.classList.length; i++) {
                            if (elem.classList[i].search(/_{2}/g) > 0) {
                                str = elem.classList[i];
                                break;
                            }
                        }

                        // no assignment on string earlier? - bail out
                        if (typeof str === 'undefined') {
                            if (elem.classList.length > 1) {
                                vio.debug.log('Trying to get a BEM class on an element with multiple generic css classes so assuming first class found to be the BEM class');
                            }
                            str = elem.classList[0];
                        }
                        return str;
                    };

                    var build_bem_class_name = function(components) {
                        var class_name = components.module;
                        if (components.element != false) {
                            class_name += '__' + components.element;
                        }
                        if (components.modifier != false) {
                            class_name += '--' + components.modifier;
                        }
                        return class_name;
                    };

                    // public methods
                    return {
                        get_all: function(param) {
                            var components;

                            // are we dealing with DOM node?
                            if (typeof param.nodeName !== 'undefined') {
                                var css;
                                if (param.classList) {
                                    css = param.classList.toString();
                                } else {
                                    css = param.className;
                                }
                                css = css.split(' ');
                                for (var i=0; i<css.length; i++) {
                                    components = vio.util.merge_objects(components, get_components(css[i]));
                                }

                            // assume it's a string
                            } else {
                                components = get_components(param);
                            }

                            return components;
                        },
                        get_module: function(param) {
                            var components = this.get_all(param);
                            return components.module;
                        },
                        get_element: function(param) {
                            var components = this.get_all(param);
                            return components.element;
                        },
                        get_modifier: function(param) {
                            var components = this.get_all(param);
                            return components.modifier;
                        },
                        add_modifier: function(element, modifier) {
                            var components = this.get_all(element);
                            components.modifier = modifier;
                            var class_name = build_bem_class_name(components);
                            vio.util.dom.add_class(element, class_name);
                        },
                        remove_modifier: function(element, modifier) {
                            var components = this.get_all(element);
                            components.modifier = modifier;

                            var class_name = build_bem_class_name(components);
                            vio.util.dom.remove_class(element, class_name);
                        },
                        has_modifier: function(element, modifier) {
                            var components = this.get_all(element);
                            return components.modifier == modifier;
                        },
                        toggle_modifier: function(element, modifier) {
                            var components = this.get_all(element);
                            components.modifier = modifier;
                            var class_name = build_bem_class_name(components);
                            vio.util.dom.toggle_class(element, class_name);
                        }
                    }
                }(),

                has_class: function(param, class_name) {
                    var element = get_element(param);
                    if (typeof element.nodeName === 'undefined') {
                        throw 'attempting to test for a class_name on a non-DOM-element';
                    }
                    if (element.classList) {
                        return element.classList.contains(class_name);
                    } else {
                        return new RegExp('(^| )' + class_name + '( |$)', 'gi').test(element.className);
                    }

                },
                add_class: function(param, class_name) {
                    get_element(param).classList.add(class_name);
                },
                remove_class: function(param, class_name) {
                    get_element(param).classList.remove(class_name);
                },
                toggle_class: function(param, class_name) {
                    if (vio.util.dom.has_class(param, class_name)) {
                        vio.util.dom.remove_class(param, class_name);
                    } else {
                        vio.util.dom.add_class(param, class_name);
                    }
                },
                is_visible: function(element) {
                    return element.style.display != 'none';
                },
                is_hidden: function(element) {
                    return element.style.display == 'none';
                },
                show: function(param, style) {
                    if (typeof style === 'undefined') {
                         style = 'inline-block';
                    }
                    if (typeof param.nodeName !== 'undefined') {
                        param.style.display = style;
                    } else {
                        process_elements(param, function (el) {
                            el.style.display = style;
                        });
                    }
                },
                hide: function(param) {
                    if (typeof param.nodeName !== 'undefined') {
                        param.style.display = 'none';
                    } else {
                        process_elements(param, function (el) {
                            el.style.display = 'none';
                        });
                    }
                },
                toggle: function(param) {
                    var toggle = function(el) {
                        if (vio.util.dom.is_hidden(el) === true) {
                            vio.util.dom.show(el);
                        } else {
                            vio.util.dom.hide(el);
                        }
                    };

                    if (typeof param.nodeName !== 'undefined') {
                        toggle(param);
                    } else {
                        process_elements(param, toggle);
                    }
                },
                add_event_listener: function(element, event_name, func) {
                    // each element must have an
                    if (element.getAttribute('data-unique-event-handler-id') == null) {
                        element.setAttribute('data-unique-event-handler-id', vio.util.text.hash(vio.util.random(1, new Date().getTime())));
                    }
                    var hash = element.getAttribute('data-unique-event-handler-id') + event_name + vio.util.text.hash(func.toString());
                    if (typeof event_handlers[hash] !== 'undefined') {
                        vio.debug.log('Trying to set same event handler twice');
                        return false;
                    }

                    // modify the handler
                    func.handler_hash = hash;
                    func.handler_target = element;
                    func.get_handler_hash = function() {
                        return this.handler_hash;
                    };
                    func.get_handler_target = function() {
                        return this.handler_target;
                    };

                    // store internally
                    event_handlers[hash] = func;

                    // attach handler to element
                    if (element.attachEvent) {
                        return element.attachEvent('on' + event_name, func);
                    } else {
                        return element.addEventListener(event_name, func, false);
                    }
                },
                trigger_event: function(element, event_name, payload) {
                    var event;
                    if (document.createEvent) {
                        event = document.createEvent("HTMLEvents");
                        event.initEvent(event_name, true, true);
                    } else {
                        event = document.createEventObject();
                        event.eventType = event_name;
                    }
                    event.eventName = event_name;
                    if (typeof payload !== 'undefined') {
                        event.payload = payload;
                    }
                    if (document.createEvent) {
                        element.dispatchEvent(event);
                    } else {
                        element.fireEvent("on" + event.eventType, event);
                    }
                },
                is_descendant: function(parent, child) {
                    var node = child.parentNode;
                    while (node !== null) {
                        if (node === parent) {
                            return true;
                        }
                        node = node.parentNode;
                    }
                    return false;
                },

                /**
                 * Finds nearest parent having a css class
                 *
                 * @note - css_class_name should NOT start with dot
                 * @note - css_class_name can be a tag name
                 * @param element
                 * @param css_class_name
                 * @returns {*}
                 */
                parent: function (element, css_class_name) {
                    return element && ( // if element is not falsy
                        (
                            vio.util.dom.has_class(element, css_class_name) || // has css class OR
                            (
                                typeof element.nodeName !== 'undefined' && // HAS nodeName
                                element.nodeName == String(css_class_name).toUpperCase() // nodeName is css_class_name
                            )
                        )
                        ? element // return element
                        : (this.parent(element.parentNode, css_class_name)) // recursively call again with parent node
                    );
                }
            };
        }()
    };
}(vio);

/**
 * @dependency vio.util
 * @dependency vio.config
 */
vio.router = function(vio) {
    // private functions:

    /**
     * Returns a static domain for this site
     * @returns {string}
     */
    var get_static_domain = function() {
        var domains = vio.config.site.domains;
        return domains[vio.util.random(0, domains.length - 1)];
    };

    // public functions
    return {
        /**
         * Returns URL to static asset for this site
         * @param path
         * @returns {string}
         */
        asset_url: function (path) {
            var domain = get_static_domain();
            var url = '//' + domain;
            if (typeof path !== 'undefined') {
                url += '/' + path;
            }
            return url;
        }
    };

}(vio);

/**
 * Simple wrapper around Google Analytics, for DEV purposes we create a
 * for dev purposes - we are going to mock ga if it's not available
 *
 * @dependency Google Analytics - but I can't just pass it in as it has to be an optional dependency
 * @dependency vio.debug
 */
vio.track = function(vio) {
    if (typeof ga == 'undefined') {
        vio.debug.log('Unable to track to GA - events will be stored only locally');
    }
    var events = [];

    var log = function(command, type, category, action, label, value, obj) {
        var payload = {
            command:  command,
            type:     type,
            category: category,
            action:   action,
            label:    label,
            value:    value,
            object:   obj
        };

        // validate
        var allowed = {create:true, send:true, require:true};
        if (command in allowed !== true) {
            throw 'Trying to use "' + command + '" for GA tracking command, only: "' + allowed.join('", "') + '" are allowed';
        }
        allowed = {pageview:true, event:true, social:true, timing:true};
        if (type in allowed !== true) {
            throw 'Trying to use "' + type + '" for GA tracking type, only: "' + allowed.join('", "') + '" are allowed';
        }
        if (typeof value !== 'undefined' && /^\d+$/.test(value)) {
            throw 'Trying to use non-numeric "value": ' + value + ' for GA value field.';
        }

        events.push(payload);

		if (typeof front_end_config !== 'undefined' && front_end_config.build.env === 'dev') {
            vio.debug.log('Triggering GA event', payload);
        }

        if (typeof ga != 'undefined') {
            // we don't know how many params have been passed as undefined - so we have to rebuild them as array
            var params = [];
            for (var i in payload) {
                if (typeof payload[i] != 'undefined') {
                    params.push(payload[i]);
                }
            }

            ga.apply(window, params);
        }
    };

    return {
        event: function(category, action, label, value) {
            log('send', 'event', category, action, label, value);
        },
        dump: function() {
            vio.debug.log('All events captured', events);
        }
    };
}(vio);


/**
 * Any item having "data-module-autocomplete" will be instantiated.
 * @dependency jQuery
 * @dependency vio.util
 * @dependency vio.track
 */
vio.autocomplete = function(vio, $) {
    var module_elements = document.querySelectorAll('[data-module-autocomplete]'),
        config, data, j;

    // validate we have everything we need
    if (typeof $.fn.smartSuggest !== 'function') {
        console.log('No smartSuggest library included');
        return;
    }

    // iterate through all the module_elements extract the configuration and instantiate the jQuery plugin
    var module_name, parents, parent_element, forms, form;
    for (var i=0; i<module_elements.length; i++) {
        data = vio.util.dom.data(module_elements[i]);
        config = {};
        for (j in data) {
            config[vio.util.text.camelise(j)] = data[j];
        }

        vio.debug.log('Instantiating jQuery smartSuggest plugin', config);
        $(module_elements[i]).smartSuggest(config);

        // find the name of the module - so we can find the parent
        module_name = vio.util.dom.bem.get_module(module_elements[i]);
        parents = document.querySelectorAll('.' + module_name);
        forms = document.querySelectorAll('.' + module_name + ' form');

        // there should only be one - but if there are multiple ones - then the "i" - is the right one
        parent_element = parents[i];
        form = forms[i];

        // hide the overlay for initial state
        var overlay_selector = '.' + module_name + '__overlay';

        // use closure so we get the references right
        (function(el, overlay, parent) {
            // attach an event handler that will apply "--active" modifier to the wrapper element - in BEM - "module__element--modifier"
            vio.util.dom.add_event_listener(el, 'focus', function() {
                vio.util.dom.bem.add_modifier(parent, 'active');
            });

            // and one taking it off
            vio.util.dom.add_event_listener(el, 'blur', function() {
                vio.util.dom.bem.remove_modifier(parent, 'active');
            });

            // ESC to blur element
            /*
            vio.util.dom.add_event_listener(el, 'keydown', function(event) {
                event = event || window.event;
                if (event.keyCode === 27) {
                    vio.util.dom.trigger_event(el, 'blur');
                    vio.util.dom.trigger_event(document.getElementById('bodyarea'));
                }
            });
            */

            // ga tracking on form submit - I had to use jQuery here as the smartSuggest plugin is hacked and triggers jQuery event on the form directly.
            // this causes the jquery to simply submit the form and ignores any HTML event handlers that might be attached to it.
            $(form).on('submit', function() {
                vio.track.event('search', 'keyword', el.value);
            });
        }(module_elements[i], overlay_selector, parent_element));
    }
};

/**
 * It doesn't require jQuery per se - but because the rest of the code uses jQuery's submit handlers we have to submit them in the same way
 * @dependency jQuery
 * @dependency vio.util
 * @param vio
 * @param $
 */
vio.customer_rating_selector = function(vio, $) {
    var module_elements = document.querySelectorAll('[data-module-customer-rating-selector]');
    if (module_elements.length == 0) {
        return;
    }

    // module container element
    var module_container = module_elements[0];

    // if we have item selected - hide the others and leave the button to remove selection
    var val, link, del, form, input, lis;
    form = vio.util.dom.parent(module_container, 'form');
    input = module_container.querySelector('input');
    lis = module_container.querySelectorAll('.js-filter-section__star-rating-list-item');

    // selected value as integer handling NaN
    var selected = parseInt(input.value, 10);
    selected = (isNaN(selected)) ? 0 : selected;

    // what happens when they click on a star?
    var clicked_start = function(event) {
        var li = vio.util.dom.parent(event.target, 'li');
        input.value = parseInt(li.getAttribute('data-value'), 10);
        $(form).submit();
    };

    // what happens when they click on delete button?
    var clicked_del = function(event) {
        var li = vio.util.dom.parent(event.target, 'li');
        input.value = 0;
        $(form).submit();
    };

    // iterate and apply relevant stuff
    for (var i=0; i<lis.length; i++) {
        val = parseInt(lis[i].getAttribute('data-value'), 10);
        link = lis[i].querySelector('.js-click-handler');
        del = lis[i].querySelector('.js-remove-button');

        // only show one row and activate delete button
        if (selected > 0) {
            // not the selected row
            if (val !== selected) {
                vio.util.dom.hide(lis[i]);
            // selected row - attach click handler to remove button
            } else {
                // show del button and apply style
                vio.util.dom.show(lis[i].querySelector('.js-remove-button'));
                del.style.cursor = 'pointer';

                // attach handler
                vio.util.dom.add_event_listener(del, 'click', clicked_del);
            }

        // nothing selected - show all rows
        } else {
            // attach a click handler to links over stars
            vio.util.dom.add_event_listener(link, 'click', clicked_start);

            // add the pointer style cursor
            link.style.cursor = 'pointer';

            // hide the del button
            vio.util.dom.hide(lis[i].querySelector('.js-remove-button'));
        }
    }
};

var addressing_module_instances = {};

/**
 * Addressing module will trigger some custom events for you to hook into. They will all trigger on the ".address" wrapper of the form
 * @event: addressing_module_pca_populated         - when customer picked something from pca capture widget
 * @event: addressing_module_form_reload_complete  - when reloading of the address form finished
 * @event: addressing_module_pca_initialised       - PCA instantiated
 * @event: addressing_module_pca_error             - PCA errored out
 * @event: addressing_module_pca_country_selected  - user selected a country from PCA widget
 * @event: addressing_module_pca_address_manual    - user could not find the address and switched to manual input mode
 * @event: addressing_module_pca_search_started    - user entered a string and search started
 * @event: addressing_module_pca_results_shown     - search results shown
 * @event: addressing_module_pca_no_results        - no results matched
 * @event: addressing_module_switch_to_manual      - user clicked "enter manually"
 * @event: addressing_module_switch_to_automatic   - user clicked "search automatically"
 */
vio.addressing_module = function(vio, $, pca) {
	var module_url = '/addressing_module.php?';
	var prefix, i, container, containers;

	// check if PCA predict library is loaded
	var pca_config = typeof window.front_end_config.addressing_module !== 'undefined' ? window.front_end_config.addressing_module : false;
	if (pca_config === false) {
		if (typeof front_end_config !== 'undefined' && front_end_config.build.env === 'dev') {
			vio.debug.log('Required window.front_end_config.addressing_module is missing');
		}
		return;
	}

	var reset = function (prefix) {
		// console.log('====RESET');
		// console.log($('#' + prefix + ' :input'));
		var form_wrapper = $('#' + prefix).find('.address__container--form');

		// reuse the same country
		var country_code = $(form_wrapper).attr('data-country-code');
		$('#' + prefix + ' span[data-address-part="countryCode"] :input').val(country_code);

        $('#' + prefix + ' span[data-address-part="administrativeArea"] :input').val('');
        $('#' + prefix + ' span[data-address-part="locality"] :input').val('');
        $('#' + prefix + ' span[data-address-part="dependentLocality"] :input').val('');
        $('#' + prefix + ' span[data-address-part="postalCode"] :input').val('');
        $('#' + prefix + ' span[data-address-part="sortingCode"] :input').val('');
        $('#' + prefix + ' span[data-address-part="addressLine1"] :input').val('');
        $('#' + prefix + ' span[data-address-part="addressLine2"] :input').val('');
        $('#' + prefix + ' span[data-address-part="organization"] :input').val('');
        $('#' + prefix + ' span[data-address-part="recipient"] :input').val('');

		// reset preview manually
		$('#' + prefix).find('.address__container--preview').html('');

		// then just trigger reload
		reload_form(prefix);
	};

	var remove = function(prefix) {
        // destroy the PCA instance
		addressing_module_instances[prefix].destroy();

		// remove all event handlers
		$('#' + prefix).find('.address__container--form').off();
    };

	var set_preview = function (prefix) {
		// console.log('====SET_PREVIEW');
		// console.log($('#' + prefix + ' :input'));

		// structure
		var address_data = {
			'country_code': $('#' + prefix + ' span[data-address-part="countryCode"] :input').val(),
			'administrative_area': $('#' + prefix + ' span[data-address-part="administrativeArea"] :input').val(),
			'locality': $('#' + prefix + ' span[data-address-part="locality"] :input').val(),
			'dependent_locality': $('#' + prefix + ' span[data-address-part="dependentLocality"] :input').val(),
			'postal_code': $('#' + prefix + ' span[data-address-part="postalCode"] :input').val(),
			'sorting_code': $('#' + prefix + ' span[data-address-part="sortingCode"] :input').val(),
			'address_line_1': $('#' + prefix + ' span[data-address-part="addressLine1"] :input').val(),
			'address_line_2': $('#' + prefix + ' span[data-address-part="addressLine2"] :input').val(),
			'organization': $('#' + prefix + ' span[data-address-part="organization"] :input').val(),
			'recipient': $('#' + prefix + ' span[data-address-part="recipient"] :input').val(),
			'hidden_fields': $('#' + prefix).attr('data-hidden-fields').split(',')
		};

		// did we get the recipient correctly?
		if (address_data.recipient == '') {
			address_data.recipient = $('#' + prefix).closest('form').find('#first_name').val() + ' ' + $('#' + prefix).closest('form').find('#last_name').val();
		}
		// console.log('=====' + prefix + ':' + JSON.stringify(address_data));

        var loading_spinner = $('#' + prefix).find('.address__loading-address-preview');
        var preview = $('#' + prefix).find('.address__container--preview');

		$(preview).hide();
		$(loading_spinner).show();

		// send to backend and use the response as preview
		$.get(module_url + 'action=generate_preview', address_data, function (response, status, xhr) {
			$(preview).html(response);
            $(preview).show();
            $(loading_spinner).hide();
		}).fail(function() {
            $(preview).show();
            $(loading_spinner).hide();
        });
	};

	// due to the fact we need to reload the form sometimes - we will populate the form ourselves after "populate" event
	var populate = function (prefix, event) {
		// console.log('====POPULATE');
		// console.log(event);

		// get address wrapper
		var wrapper = $('#' + prefix);

		// we will map the PCA results in the backend as sometimes we need to tweak the values returned by PCA - like AdministrationArea for US needs prefix added
		$.ajax({
			url: module_url + 'action=convert_pca_to_commerce_guys',
			type: 'get',
			data: event,
			dataType: 'json',
			success: function (data, textStatus, xhr) {
				var i, el;
				for (i in data) {
					// console.log('populating ' + i + ' with: "'+data[i]+'"');
					el = $(wrapper).find('[data-address-part="' + i + '"] :input');
					el.val(data[i]);
				}

				// build preview if the page it's being used on wants to display it
				if (typeof prevent_address_preview === 'undefined' || prevent_address_preview !== true) {
					set_preview(prefix);
				}
				// trigger custom ever on the wrapper as pca internal ones don't always fire
				$(wrapper).trigger('addressing_module_pca_populated');
			}
		});
	};

	// reloads the form for given country
	var reload_form = function (prefix, country_code, populate_event) {
		// console.log('====RELOAD_FORM');
		var form_wrapper = $('#' + prefix).find('.address__container--form');
		var hidden_fields = $('#' + prefix).closest('[data-module="address-instance"]').attr('data-hidden-fields').split(',');

		// default to form's current country code
		if (typeof country_code === 'undefined') {
		    country_code = $(form_wrapper).attr('data-country-code');
        }

		// grab all the address data, country code is slightly different as we need "selected" country code and not the default one
		var address_data = {
			'country_code': country_code,
			'administrative_area': $(form_wrapper).attr('data-administrative-area'),
			'locality': $(form_wrapper).attr('data-locality'),
			'dependent_locality': $(form_wrapper).attr('data-dependent-locality'),
			'postal_code': $(form_wrapper).attr('data-postal-code'),
			'sorting_code': $(form_wrapper).attr('data-sorting-code'),
			'address_line_1': $(form_wrapper).attr('data-address-line-1'),
			'address_line_2': $(form_wrapper).attr('data-address-line-2'),
			'organization': $(form_wrapper).attr('data-organization'),
			'recipient': $(form_wrapper).attr('data-recipient'),
			'locale': $(form_wrapper).attr('data-locale'),
			'hidden_fields': hidden_fields
		};

		// backend action
		address_data.action = 'regenerate_subform';

		// we also need a prefix - to regenerate the same form
		address_data.prefix = prefix;

		// get new form
		$.get(module_url, address_data, function (response, status, xhr) {
			// set form
			$(form_wrapper).html(response);

			// trigger custom event
			$('#' + prefix).trigger('addressing_module_form_reload_complete');

			// if we have populate event - call populate
			if (typeof populate_event !== 'undefined' && populate_event !== null) {
				populate(prefix, populate_event);
			}
		});
	};

	// selects given countryCode
	var selectCountry = function (prefix, countryCode) {
		// console.log('====SELECT_COUNTRY');

		var option_to_select = $('#' + prefix).find('.country option[value="' + countryCode + '"]');
		$(option_to_select).attr('selected', 'selected');
	};

	var initializeContainer = function (prefix) {
		container = $('#' + prefix);

		// initialize only once
		if ($(container).attr('data-initialized') === 'true') {
			return;
		}

		// @see all possible event handlers: https://www.pcapredict.com/support/articles/article/captureplus-javascript-guide/#events_listeners
		// @see possibly all options: http://www.pcapredict.com/capture-plus/reference/2.20/pca.address.html#Options
		addressing_module_instances[prefix] = new pca.Address(
			[
				{ element: prefix + "_search_box", field: "Search", mode: pca.fieldMode.SEARCH }
			],
			{
				key: pca_config.pca_key,
				suppressAutocomplete: true,
				search: {
					countries: pca_config.whitelisted_countries.join(',')
				},
				manualEntryItem: true, // if we use a dedicated button - it's probably best NOT to show this - but if we do, make sure we have a handler for "manual" event in place
				bar: {
					visible: true,
					showCountry: true,
					showLogo: false
				}
			}
		);

		// as PCA events don't point to a target - we will "retrigger" it against the "current" element
		addressing_module_instances[prefix].listen('country', function () {
			var current_prefix = prefix;
			return function (event) {
				// console.log('====PCA COUNTRY EVENT');
				// console.log(event);

				// select the right thing in the dropdown
				selectCountry(current_prefix, event.iso2);

				// trigger the change event on dropdown
				$('#' + current_prefix).find('.country').trigger({type: 'change', country_event: event});

				// empty the search box
				$('#' + current_prefix).find('.address__container--postcode-anywhere').val('');
			};
		}());

		// when user selects something from pcacapture thing
		addressing_module_instances[prefix].listen('populate', function () {
			var current_prefix = prefix;
			return function (event) {
				// console.log('====PCA POPULATE EVENT');
				// console.log(event);

				// first thing we need to check is to check if the country is different from the one we have on the page currently - if so - we need to defer the effect of "populate" event
				var selected_country_code = $('#' + current_prefix).find('.address__container--country-dropdown option:selected').val();

				// empty the search box
				$('#' + current_prefix + '_search_box').val('');

				// do we need to update the country?
				if (selected_country_code !== event.CountryIso2) {
					selectCountry(current_prefix, event.CountryIso2);

					// trigger the change and pass on populate event - so once the form reloads it will trigger populate()
					$('#' + current_prefix).find('.address__container--country-dropdown').trigger({
						type: 'change',
						populate_event: event
					});

                // otherwise populate straight away
				} else {
					populate(current_prefix, event);
				}
			};
		}());

		// when we receive "manual" - we show the form and country dropdown
		addressing_module_instances[prefix].listen('manual', function () {
			var current_prefix = prefix;
			return function (event) {
				// console.log('====PCA MANUAL EVENT');
				// console.log(event);
				$('#' + current_prefix).find('.address__postcode-anywhere-manual-input').click();
			};
		}());

		// when we receive "error" - we have to indicate this somehow
		// example: {"Items":[{"Error":"1005","Description":"No response","Cause":"The query didn\u0027t respond fast enough, it may be too complex.","Resolution":"Please check what you entered and try again with something more specific."}]}
		addressing_module_instances[prefix].listen('error', function () {
			var current_container = container;
			return function (event) {
				// console.log('====PCA ERROR EVENT');
				// console.log(event);
				// hide spinner
				$(current_container).find('.address__postcode-anywhere-spinner').hide();
				$(current_container).trigger('addressing_module_pca_error');

			};
		}());

		// attach handler to country dropdown - whenever the country dropdown changes - we must reload the form
		$(container).find('.address__container--country-dropdown').on('change', function () {
			var current_prefix = prefix;
			return function (event) {
				// console.log('====CHANGE');
				// console.log(event);

				// do we have populate event?
				var populate_event = (typeof event.populate_event !== 'undefined') ? event.populate_event : null;
				var selected_country_code = $(event.target).find('option:selected').val();

				if ($.inArray(selected_country_code, pca_config.whitelisted_countries) === -1) {
					$('#' + current_prefix).find('.address__postcode-anywhere-manual-input').click();
				} else {
					$('#' + current_prefix).find('.address__postcode-anywhere-automatic-input').click();
				}

				// always trigger for external hooks
				$('#' + current_prefix).trigger('addressing_module_pca_country_selected');

				// reload the form with data from "populate" event
				reload_form(current_prefix, selected_country_code, populate_event);
			};
		}());

		// attach handler to postcode anywhere search event to show the spinner
		addressing_module_instances[prefix].listen('search', function () {
			var current_container = container;
			return function (event) {
				// console.log('====SEARCH');
				// console.log(event);
				$(current_container).find('.address__postcode-anywhere-spinner').show();
				$(current_container).trigger('addressing_module_pca_search_started');
			};
		}());

		// attach handler to show event - as on some pages we need to manually reposition and reenable certain things in order for it to work
		addressing_module_instances[prefix].listen('show', function () {
			var current_container = container;
			return function (event) {
				// console.log('====SHOW');
				// console.log(event);
				// it's a complete hack... annoyingly...
				if ($('body').hasClass('_noscroll')) {
					var input_element_position = $(current_container).find('.address__container--postcode-anywhere').get(0);
					var pcaWrapper = $('body > .pca').first();
					$(pcaWrapper).offset({top: $(input_element_position).offset().top - 55}); // no idea why 55, and am fed up with fixing CSS
				}
			};
		}());

		// attach 2 handlers to postcode anywhere results and noresults events to hide the spinner
		addressing_module_instances[prefix].listen('results', function () {
			var current_container = container;
			return function (event) {
				// console.log('====RESULTS');
				// console.log(event);
				$(current_container).find('.address__postcode-anywhere-spinner').hide();
				$(current_container).trigger('addressing_module_pca_results_shown');
			};
		}());

		addressing_module_instances[prefix].listen('noresults', function () {
			var current_container = container;
			return function (event) {
				// console.log('====NO RESULTS');
				// console.log(event);
				$(current_container).find('.address__postcode-anywhere-spinner').hide();
				$(current_container).trigger('addressing_module_pca_no_results');
			};
		}());

		// attach handler to "search manually" button
		$(container).find('.address__postcode-anywhere-manual-input').on('click', function () {
			var current_container = container;
			var current_prefix = prefix;
			return function (event) {
				// console.log('====CLICK on manual');
				// console.log(event);
				$(current_container).find('.address__container--postcode-anywhere').hide();
				$(current_container).find('.address__container--preview').hide();
				$(current_container).find('.address__container--country-dropdown').show();
				$(current_container).find('.address__container--form').show();

				// hide manual search button if selected country is NOT in the whitelist
				var selected_country_code = $(current_container).find('.address__container--country-dropdown option:selected').val();
				if ($.inArray(selected_country_code, pca_config.whitelisted_countries) > -1) {
					$(current_container).find('.address__container--search-automatically').show();
				} else {
					$(current_container).find('.address__container--search-automatically').hide();
                }
				$(current_container).find('.address__container--enter-manually').hide();

				// once manual is selected it disables the widget so it stops listening to events as well
                // problem is we can't simply do it straight away as PCA assumes the input will still be visible.
				setTimeout(function() {
					$(current_container).find('.address__postcode-anywhere-input').val('').blur();
					addressing_module_instances[current_prefix].destroy();
					addressing_module_instances[current_prefix].load();
					addressing_module_instances[current_prefix].disable();
				}, 500);

				$(current_container).trigger('addressing_module_switch_to_manual');
			};
		}());

		// attach handler to "search automatically" button
		$(container).find('.address__postcode-anywhere-automatic-input').on('click', function () {
			var current_container = container;
			var current_prefix = prefix;
			return function (event) {
				// console.log('====CLICK on automatic');
				// console.log(event);
				$(current_container).find('.address__container--postcode-anywhere').show();
				$(current_container).find('.address__container--preview').show();
				$(current_container).find('.address__container--country-dropdown').hide();
				$(current_container).find('.address__container--form').hide();
				$(current_container).find('.address__container--search-automatically').hide();
				$(current_container).find('.address__container--enter-manually').show();

				addressing_module_instances[current_prefix].enable();

				$(current_container).trigger('addressing_module_switch_to_automatic');

				// set_preview(current_prefix);

				// disable all the validation messages on the form being hidden
				// $(current_container).closest('form').validate().resetForm();
			};
		}());

		// listen for "preview" events on the container
		$(container).on('preview', function () {
			var current_prefix = prefix;
			return function (event) {
				// console.log('====PREVIEW EVENT');
				// console.log(event);
				set_preview(current_prefix);
			};
		}());

		// listen for "reset" events on the container
		$(container).on('reset', function () {
			var current_prefix = prefix;
			return function (event) {
				// console.log('====RESET EVENT');
				// console.log(event);
				reset(current_prefix);
			};
		}());

		$(container).on('remove', function() {
			var current_prefix = prefix;
			return function (event) {
				// console.log('====REMOVE EVENT');
				// console.log(event);
				remove(current_prefix);
			};
        }());

		// hide the address form and country dropdown leaving only search box, we unhide on "manual" event from pca capture
		$('.address__container--form').hide();
		$('.address__container--country-dropdown').hide();
		$('.address__container--search-automatically').hide();
		$('.address__container--enter-manually').show();
		$('.address__postcode-anywhere-spinner').hide();

		$(container).attr('data-initialized', 'true');

		// make sure the whitelist has at least one country - if not default to manual
        if (pca_config.whitelisted_countries.length === 0) {
            $('.address__postcode-anywhere-manual-input').click();
        }

		// trigger custom event
		$(container).trigger('addressing_module_pca_initialised');
	};

	// module's bootstrap code
	// if no addresses on the page - return
	var containers = document.querySelectorAll('[data-module="address-instance"]');
	if (containers.length === 0) {
		return;
	}

	// add each container
	for (i = 0; i < containers.length; i++) {
		prefix = containers[i].getAttribute('id');
		initializeContainer(prefix);
	}
};

/**
 * Bootstrap - execute all modules code ONLY when the DOM has loaded,
 * Note, that this is all a workaround to proper module structure - as normally we would just have controllers / actions that would trigger relevant modules
 * If a module can be executed before DOM - just execute it above, if it needs to be started after DOM has loaded - instantiate it here
 * Please note the difference between autocomplete and router for example, router gets called inline - it has "(vio)" at the end of a definition, while autocomplete does not
 */
vio.bootstrap(function() {
    vio.autocomplete(vio, jQuery);
    vio.customer_rating_selector(vio, jQuery);
    var current_page = window.location.pathname;
    if(current_page != '/checkout.php') {
        // on checkout v2 we load this from vue, otherwise IE says no and doesn't load
        vio.addressing_module(vio, jQuery, window.pca);
    }
});

// TODO integrate better with dialog
vio.alert = function (message, options) {
	var defaults = {
		title: 'Alert',
		message: message
	};

	var settings = $.extend({}, defaults, options);

	notification_dialog(settings.message, settings.title);
};